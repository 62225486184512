<template>
<div class="content">
  <section id="settings">
    <div class="col1 max-800">
      <h1>Settings</h1>
      <p>Update your profile</p>

      <transition name="fade">
        <p v-if="showSuccess" class="success">profile updated</p>
      </transition>

      <form @submit.prevent>
        <label for="name">Name</label>
        <input id="name" v-model.trim="name" type="text" :placeholder="userProfile.name" />

        <label for="title">Job Title</label>
        <input id="title" v-model.trim="title" type="text" :placeholder="userProfile.title" />

        <label for="image">Image</label>
        <input id="image" v-model.trim="image" type="text" :placeholder="userProfile.image" />

        <button @click="updateProfile()" class="btn">Update Profile</button>
      </form>
    </div>
  </section>
</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      name: '',
      title: '',
      image: '',
      showSuccess: false
    }
  },
  computed: {
    ...mapState(['userProfile'])
  },
  methods: {
    updateProfile() {
      this.$store.dispatch('updateProfile', {
        name: this.name !== '' ? this.name : this.userProfile.name,
        title: this.title !== '' ? this.title : this.userProfile.title,
        image: this.image !== '' ? this.image : this.userProfile.image
      })

      this.name = ''
      this.title = ''
      this.image = ''

      this.showSuccess = true

      setTimeout(() => {
        this.showSuccess = false
      }, 2000)
    }
  }
}
</script>
